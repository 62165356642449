import React from "react";
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import WorkIcon from '@material-ui/icons/Work';
//import HistoryIcon from '@material-ui/icons/History';
import Build from "@material-ui/icons/Build";
// core components
import GridContainer from "../../../../components/grid/grid-container.component";
import GridItem from "../../../../components/grid/grid-item.component";
import CustomTabs from "../../../../components/tabs/tabs.component";
import Button from "../../../../components/button/button.component";

const styles = {
  section: {
    padding: "10px 0",
    color: "black",
  },
  title: {
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  justify: {
    float: "right",
  },
};

const useStyles = makeStyles(styles);

function ProjectSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="nav-tabs">
        <h2 className={classes.title}>
          Most Recent Projects 
          <Link to="/project-page">
            <Button color="primary" className={classes.justify} size="sm">Click To See More</Button>    
          </Link>
        </h2>
        
          <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
              <CustomTabs
                headerColor="primary"
                tabs={[
                  {
                    tabName: "Project Name",
                    tabIcon: WorkIcon,
                    tabContent: (
                      <p className={classes.textCenter}>
                        <b>Matt Sernett</b> -
                        A web site for Matt who is an award winning game and narrative designer, world builder for 100 million dollar brand and game designer for a game played by millions (including me).
                        <br/>
                        <a href="https://www.mattsernett.com/" target="_blank" rel="noopener noreferrer">
                          <Button color="primary" className={classes.justify} size="sm">Finished Site</Button>
                        </a> 
                      </p>
                    ),
                  },
                  {
                    tabName: "Tech Stack",
                    tabIcon: Build,
                    tabContent: (
                      <p className={classes.textCenter}>
                        <ul>
                          <li>React</li>
                          <li>Redux</li>
                          <li>HTML/CSS/SaSS</li>
                          <li>GitHub</li>
                        </ul>
                      </p>
                    ),
                  },
                ]}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomTabs
                headerColor="primary"
                tabs={[
                  {
                    tabName: "Project Name",
                    tabIcon: WorkIcon,
                    tabContent: (
                      <p className={classes.textCenter}>
                        <b>React Blog using MERN stack</b>- Moving right along. This time I followed Lama Dev over
                         a three part series creating a front end using React hooks and a Context API with <b>M</b>ongoDB,
                          <b>E</b>xpress, <b>R</b>eact and <b>N</b>odeJS. Very good tutorial.
                      </p>
                    ),
                  },
                  {
                    tabName: "Tech Stack",
                    tabIcon: Build,
                    tabContent: (
                      <p className={classes.textCenter}>
                       <ul>
                         <li>MongoDB</li>
                         <li>Express</li>
                         <li>React</li>
                         <li>NodeJs</li>
                         <li>HTML/CSS</li>
                         <li>GitHub</li>
                       </ul>
                      </p>
                    ),
                  },
                ]}
              />
            </GridItem>
          </GridContainer> 
        </div>
      </div>
    </div>
  );
}

export default ProjectSection;