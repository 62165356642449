import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import CodeIcon from '@material-ui/icons/Code';

// core components
import GridContainer from "../../../../components/grid/grid-container.component";
import GridItem from "../../../../components/grid/grid-item.component";
import NavPills from "../../../../components/navpills/navpills.component";

const styles = {
  section: {
    padding: "10px 0",
    color: "black",
  },
  title: {
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

function ProfileSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="navigation-pills">
          <div>
          <h2 className={classes.title}>Let's talk <b>TEC</b>!</h2>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={9} lg={12}>
              <NavPills
                color="primary"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 3, md: 2, lg: 2},
                  contentGrid: { xs: 12, sm: 9, md: 10, lg: 10},
                }}
                tabs={[
                  {
                    tabButton: "Travel",
                    tabIcon: FlightTakeoffIcon,
                    tabContent: (
                      <span>
                        <p>
                          I love to travel! But who doesnt, right? No, really, does
                          anyone not like to travel? Covid kinda put a damper 
                          on the whole travel thing. But honestly, Covid 
                          put a damper on just about everything. Hopefully we return to some sort of normal
                          sooner than later.
                        </p>
                     
                        <p>
                          That said, some of my favorite places to visit are Thailand, United Kindom, France, Italy and China. 
                          There is plenty to love about the other places I have been, but those are kind of my top 5
                          at the moment. If you have a recommendation, feel free to click the connect button at the bottom of
                          the page and let me know. Always appreaciate recommendations.
                        </p>
                   
                        <p>
                          Either way, the world is my bucket list and I am hopeful that when I leave
                          this place, my bucket is full.
                        </p>
                      </span>
                    ),
                  },
                  {
                    tabButton: "Eat",
                    tabIcon: RestaurantMenuIcon,
                    tabContent: (
                      <span>
                        <p>
                          I love to eat! But who doesnt, right? No, really, does
                          anyone not like to eat?
                        </p>
                   
                        <p>
                          What do I love to eat? Just about anything and everything.
                          Why do i travel? so I can eat the many wonderful things 
                          out there in the world. I will generally try anything at least once. Recommendations? You know the drill. Send them my way.
                        </p>
                        <p>
                          There was a time when I dreamt of being a Chef and I do still love
                          to cook every once in awhile (I make a killer New York Cheesecake).
                          But eating is the bees kness. 
                        </p>
                      </span>
                    ),
                  },
                  {
                    tabButton: "Code",
                    tabIcon: CodeIcon,
                    tabContent: (
                      <span>
                        <p>
                          I love to code! Ok, I wont do that to you again as I
                          appreaciate the fact you got this far. A life long gamer, I didnt know 
                          much about software development until college when I needed one more elective.
                          So I opted for Intro to programming using c++.
                        </p>
                      
                        <p>
                          I had no idea what i was doing. Was terribly frustrated. Barely passed the class and am pretty
                          sure my professor knew I didnt belong. But something happened. It felt like
                          solving puzzels trying to make things work. As an added bonus, being able to take an idea and create something tangible.
                        </p>
                        
                        <p>
                          That was it. I was hooked, and from that point on, I knew what I wanted to do.
                          Career took me on a different path for awhile with the whole Agile Coaching thing. But I am back and loving all
                          the new things I am learning along the way.
                        </p>
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
           
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default ProfileSection;
