import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import { Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

// core components
import GridContainer from "../../../../components/grid/grid-container.component";
import GridItem from "../../../../components/grid/grid-item.component";
import CustomInput from "../../../../components/input/input.component";
import Button from "../../../../components/button/button.component";

import emailjs from 'emailjs-com';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = {
    section: {
        padding: "70px 25px",
      },
      title: {
        color: "#3C4858",  
        marginBottom: "50px",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign: "center",
      },
      description: {
        color: "#999",
        textAlign: "center",
      },
      textCenter: {
        textAlign: "center",
      },
      textArea: {
        marginRight: "15px",
        marginLeft: "15px",
      },
      justify: {
        display: "block",
        marginRight: "auto",
        marginLeft: "auto",
      },
  };

const useStyles = makeStyles(styles);

function ContactSection(e) {
  const classes = useStyles();
  
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function sendEmail(e) {
  e.preventDefault();

  emailjs.sendForm('service_9chicsk', 'template_045wwdk', e.target, 'user_wIofJdHtqMFP2aEYVFvK0')
    .then((result) => {
        setOpen(true);
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
    e.target.reset()
  }

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Want to Connect?</h2>
          <h4 className={classes.title}>
            Interested in some freelance web development work or need agile coaching/training? Feel free to send me an email or connect with me on LinkedIn. I will get back to you as soon as possible. 
          </h4>

        <form onSubmit={sendEmail}>  
            <GridContainer>
               <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Name"
                  id="name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  name="from_name"
                  required
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  name="user_email"
                  required
                  type="email"
                />
              </GridItem>
              <CustomInput
                labelText="Your Message"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea,
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                }}
                name="message"
              />
              <GridItem xs={12} sm={12} md={4}>
                <Button color="primary" type="submit" value="Send">Send Message</Button>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success">
                    Your message was sent.
                  </Alert>
                </Snackbar>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default ContactSection;
