import React from 'react';

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from './project-page.styles'

import HeaderNavBar from "../../components/header/header.component";
import HeaderLinks from "../../components/header/header-links.component";
import Parallax from '../../components/parallax/parallax.component';
import Footer from '../../components/footer/footer.component';
import GridContainer from '../../components/grid/grid-container.component';
import GridItem from '../../components/grid/grid-item.component';

import profileImage from "../../assets/img/pexels-negative-space-169573.jpg";

import Section2021 from './sections/project-page-2021.section';
import Section2020 from './sections/project-page-2020.section';
import SectionOlder from './sections/project-page-older.section';

const useStyles = makeStyles(styles);

function ProjectPage(props) {
    const classes = useStyles();
    
    return (
      <div>
      <HeaderNavBar
          color="transparent"
          brand="DS"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "primary",
          }}
      />
       <Parallax
          filter
          small
          image={profileImage}
        >
           <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Projects</h1>
              <h4>
                Get some insight into some of my previous development projects I have worked on
                as well as links to some of the source code on Github. Just look for the blue GitHub icon.
                </h4>
                <h4>
                Note: I did not include HTML/CSS as part of the tech stack as that has been used with just about
                every project in the below list.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
        </Parallax>
        
        <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
            <Section2021 />
            <Section2020 />
            <SectionOlder />
        </div>
      </div>
        <Footer/> 
      </div>
    );
  }
export default ProjectPage;
