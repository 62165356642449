import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @react-icons
import {IoLogoJavascript} from "react-icons/io";
import {SiGithub} from "react-icons/si";
import {SiCsharp} from "react-icons/si";
import {SiDotNet} from 'react-icons/si';
import {SiMicrosoftsqlserver} from 'react-icons/si';

// core components
import GridContainer from "../../../components/grid/grid-container.component";
import GridItem from "../../../components/grid/grid-item.component";
import Card from "../../../components/card/card.component";
import CardBody from "../../../components/card/card-body.component";
import CardFooter from "../../../components/card/card-footer.component";

import styles from './section.styles';

const useStyles = makeStyles(styles);

function Section2020() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>2015 - 2020</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                Plaster Group Agile Assesment
                <br />
                <small className={classes.smallTitle}>2020</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Developed an online application to capture assessment details
                  with various clients in order to establish a baseline as well
                  as show improvements made during the engagement. 
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <SiCsharp size="28px" />
                <SiDotNet size="28px" />
                <SiMicrosoftsqlserver size="28px" />
                <SiGithub size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                Home Page 2019
                <br />
                <small className={classes.smallTitle}>2019</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  A basic peronal website using HTML/CSS and Javascript. 
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <IoLogoJavascript size="28px"/>
                <SiGithub size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                EDW Data Quality
                <br />
                <small className={classes.smallTitle}>2016</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                Developed a data quality application using C# and SQL,
                 providing teams direct access to add, update and delete
                  commonly used data quality checks for unit testing.
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <SiCsharp size="28px" />
                <SiDotNet size="28px" />
                <SiMicrosoftsqlserver size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

export default Section2020;
