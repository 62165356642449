import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @react-icons
import {FaReact} from "react-icons/fa";
import {IoLogoJavascript} from "react-icons/io";
import {SiMicrosoftazure} from "react-icons/si";
import {SiAzuredevops} from "react-icons/si";
import {SiGithub} from "react-icons/si";
import {SiCsharp} from "react-icons/si";
import {SiFirebase} from "react-icons/si";
import {SiRedux} from "react-icons/si";
import {SiPostman} from "react-icons/si";
import {SiGraphql} from 'react-icons/si';
import {FaSass} from 'react-icons/fa';
import {SiDotNet} from 'react-icons/si';
import {SiMicrosoftsqlserver} from 'react-icons/si';
import {SiHeroku} from 'react-icons/si';
import {SiMongodb} from 'react-icons/si';
import {FaNodeJs} from 'react-icons/fa';

// core components
import GridContainer from "../../../components/grid/grid-container.component";
import GridItem from "../../../components/grid/grid-item.component";
import Card from "../../../components/card/card.component";
import CardBody from "../../../components/card/card-body.component";
import CardFooter from "../../../components/card/card-footer.component";
import Button from "../../../components/button/button.component";

import styles from './section.styles';

const useStyles = makeStyles(styles);

function Section2021() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Most Recent Projects</h2>
      <div>
        <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                Matt Sernett
                <br />
                <small className={classes.smallTitle}>2021</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  A web site for Matt who is an award winning game and narrative designer, world builder for 100 million dollar brand and game designer for a game played by millions (including me).
                </p>
                <a href="https://www.mattsernett.com/" target="_blank" rel="noopener noreferrer">
                  <Button color="primary" className={classes.justify} size="sm">Finished Site</Button>
                </a> 
                <br/>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                 <FaReact size="28px"/>
                 <SiRedux size="28px" />
                  <IoLogoJavascript size="28px"/>
                  <FaSass size="28px" />
                 <SiGithub size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                React Blog using MERN stack
                <br />
                <small className={classes.smallTitle}>2021</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  <b>React Blog using MERN stack</b> - Moving right along. This time I followed Lama Dev over
                    a three part series creating a front end using React hooks and a Context API with <b>M</b>ongoDB,
                     <b>E</b>xpress, <b>R</b>eact and <b>N</b>odeJS. Very good tutorial.
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                 <SiMongodb size="28px"/>
                 <FaNodeJs size="28px"/> 
                 <FaReact size="28px"/>
                 <IoLogoJavascript size="28px"/>
                 <a href="https://github.com/dnethar1970/MERN_React_Blog" target="blank" rel="noopener noreferrer"><SiGithub size="28px" color="blue" /></a>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                React Blog using Static Data File
                <br />
                <small className={classes.smallTitle}>2021</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                In order to learn another way to build it from scratch, I followed Rizwan Khan who had put together a great tutorial rendering blog posts from a data file. Next up, I will look to render blog posts from Firebase. Keep on TEC’ing.
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                 <FaReact size="28px"/>
                 <IoLogoJavascript size="28px"/>
                 <a href="https://github.com/dnethar1970/React_Blog_Using_Static_File" target="blank" rel="noopener noreferrer"><SiGithub size="28px" color="blue" /></a>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                Home Page 2021
                <br />
                <small className={classes.smallTitle}>2021</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Wanting to further increase my understanding of React, I 
                  decided to create my personal site using it. Will continue
                  to add more functionality as I become more familiar with the
                  inner workings. So stay tuned as it is a work in progress. 
                  Much more to come.
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                 <FaReact size="28px"/>
                 <IoLogoJavascript size="28px"/>
                 <SiMicrosoftazure size="28px"/>
                 <SiAzuredevops size="28px"/>
                 <SiGithub size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                Agile Rank API
                <br />
                <small className={classes.smallTitle}>2021</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Developed an API using C#. As I am most familiar with the 
                  three tier architecture, for the API, i wanted to try out 
                  the Repository and Unit of Work patterns. Apparently, there is
                  a bit of debate on this pattern but I like it, as it keeps the
                  code clean and easy to use. 
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <SiCsharp size="28px" />
                <SiMicrosoftsqlserver size="28px" />
                <SiPostman size="28px" />
                <SiMicrosoftazure size="28px"/>
                <SiAzuredevops size="28px"/>
                <SiGithub size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
              Agile Rank Admin Portal
                <br />
                <small className={classes.smallTitle}>2021</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  I wanted to learn Blazor, so I created the Admin Portal
                  using Blazor, C#, SQL and .Net Core MVC. Using the Agile Rank API,
                  I am able to read, add, update and delete focus areas, observations
                  and recommendations for the Assessment application.
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                 <SiCsharp size="28px" />
                 <SiDotNet size="28px" />
                 <SiMicrosoftazure size="28px"/>
                 <SiAzuredevops size="28px"/>
                 <SiGithub size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                React eCommerce Site
                <br />
                <small className={classes.smallTitle}>2021</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Created an eCommerce site using React in order to learn
                  React, Redux and firebase using a real-world example. 
                  A bit of a learning curve, but well worth the effort. I'm a fan.
                </p>
                <br/>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <FaReact size="28px"/>
                <SiFirebase size="28px" />
                <SiRedux size="28px" />
                <SiGraphql size="28px" />
                <IoLogoJavascript size="28px"/>
                <FaSass size="28px" />
                <SiHeroku size="28px" />
                <a href="https://github.com/dnethar1970/React-eCommerce" target="blank" rel="noopener noreferrer"><SiGithub size="28px" color="blue" /></a>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                Blazor with Identity
                <br />
                <small className={classes.smallTitle}>2021</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  As I figure I will probably be using this set up again, I decided to 
                  create a project template. I am sure there is an easier way, but I 
                  need to know what is going on behind the scenes.
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <SiCsharp size="28px" />
                <SiDotNet size="28px" />
                 <a href="https://github.com/dnethar1970/BlazorWithIdentity" target="blank" rel="noopener noreferrer"><SiGithub size="28px" color="blue" /></a>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

export default Section2021;
