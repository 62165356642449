import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import HeaderNavBar from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";
import HeaderLinks from "../../components/header/header-links.component";
import Parallax from "../../components/parallax/parallax.component";
import styles from "./landing-page.styles";

import ProfileSection from "./sections/profile/profile-section";
import ProjectSection from "./sections/project/project-section";
import ContactSection from "./sections/contact/contact-section";
import SectionBlogTwo from "./sections/blog/blog-section-two";
//import PreFooterSection from "./sections/pre-footer/pre-footer-section";

import homeImage from "../../assets/img/seattle.jpg";
const useStyles = makeStyles(styles);

function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <HeaderNavBar
          color="transparent"
          brand="DS"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "primary",
          }}
          {...rest}
      />
      <Parallax filter image={homeImage} >
      <div className={classes.container}>
              <h1 className={classes.title}>Hi, I'm David Schlegel. <br/> <br />
              I am an Agile Coach with a background in software development, based out of Seattle, WA</h1>
              <h4>

              </h4>
              <br />
  
        </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProfileSection />
          <ProjectSection />
          <SectionBlogTwo />  
        </div>
      </div>
      <br/>
      {/*<PreFooterSection />*/}
      <ContactSection />
      <Footer />
    </div>
  );
};

export default LandingPage;
