import React from "react";
import { Link } from 'react-router-dom';

// reactstrap components
import {
  //Button,
  Card,
  CardBody,
  //CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

import './blog-section-two.styles.css';

function SectionBlogTwo() {
  return (
    <>
      <div className="section secion-blog cd-section" id="blogs">
        <div className="blog-1" id="blog-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <h2 className="title">Latest Musings</h2>
                <br />
                <Card className="card-plain card-blog">
                  <Row>
                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img"
                          src={
                            require("../../../../assets/img/pexels-negative-space-169573.jpg")
                              .default
                          }
                        />
                      </div>
                    </Col>
                    <Col md="7">
                      <CardBody>
                        <h6 className="card-category text-info">Journey</h6>
                        <CardTitle tag="h3">
                        <Link to="/blog-page-journey">
                            There and back again
                        </Link>
                        </CardTitle>
                        <p>
                            It is 4am. Wait what day is it? No matter, I need to get 
                            this thing to compile before I do anything else. 
                            Is this the beginning of the story? Or the end? 
                            Does it ever end? No, it does not...{" "}
                            <Link to="/blog-page-journey">
                                Read More
                                </Link>
                        </p>
                        <p className="author">
                          by{" "}
                            <b>Me</b>
                        </p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        </div>
    </>
  );
}

export default SectionBlogTwo;
