import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LandingPage from './pages/landing-page/landing-page';
import ProjectPage from "./pages/project-page/project-page";
import BlogPageJourney from './pages/blog-page/blog-page-journey';

import './App.css';

class App extends React.Component {
  constructor() {
    super();
    
    this.state = {
      currentUser: null
    }
  }

  render() {
    return (
        <div>
        
          <Switch>
            <Route exact path="/" render={(props) => (
              <LandingPage {...props} isAuthed={true} /> 
              )}
           />
             <Route path="/project-page" render={(props) => (
              <ProjectPage {...props} isAuthed={true} /> 
              )}
           />
            <Route path="/blog-page-journey" render={(props) => (
              <BlogPageJourney {...props} isAuthed={true} /> 
              )}
           />
          </Switch>
          
        </div>
      );
  } 
}

export default App;
