import React from 'react';

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from './blog-page.styles'

import HeaderNavBar from "../../components/header/header.component";
import HeaderLinks from "../../components/header/header-links.component";
import Parallax from '../../components/parallax/parallax.component';
import Footer from '../../components/footer/footer.component';
import GridContainer from '../../components/grid/grid-container.component';
import GridItem from '../../components/grid/grid-item.component';

import blogImage from "../../assets/img/pexels-negative-space-169573.jpg";


const useStyles = makeStyles(styles);

function BlogPageJourney(props) {
    const classes = useStyles();
    
    return (
      <div>
      <HeaderNavBar
          color="transparent"
          brand="DS"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "primary",
          }}
      />
       <Parallax
          filter
          small
          image={blogImage}
        >
           <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>There and Back Again</h1>
            </GridItem>
          </GridContainer>
        </div>
        </Parallax>
        
        <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
        <h2 className={classes.justifycenter}>
        A Developer's Journey
        </h2>
        <h4>
            Written By: David
        </h4>
          <p>
          It is 4am. Wait, what day is it? No matter, I need to get this thing to compile before I do anything else. Is this the beginning of the story? Or the end? Does it ever end? No, it does not.
          </p>
          <p>
          Yes, this is how it started with my first Intro to programming class just trying to get my application to work as expected. Wake up early. Try this snippet. Read the text. Search online. Compile. It worked! Rinse and repeat until I bang my head on the keyboard trying to decrypt the error message that tells me…nothing. Relax. Slow down. Breathe. Coffee! Yes, Coffee! Re-read the stack trace. Oh. A misspelled word. I would kick myself, but that is harder than you think. Anyway, it happens and I need to press on. Got code to write before I get back to class.
          </p>
          <p>
          That was my experience then and that is my experience now. It is part of the process. If it was easy, everyone would do it. But it is not, and is why I love it. Because it is like solving puzzles. Puzzles that work or don’t work. There is no in-between. No Grey area. Like Math. Except statistics. I hated my statistics class.
          </p>
          <p>
          My professor wasn’t kidding. You need to learn something new every 18 months or risk irrelevance. I am truly that lifelong learner.
          </p>
          <p>
          Fortunately, I have worked with software development teams while coaching and took an opportunity here and there to code alongside them and maintain at least some sort of relevance. But I still have much to learn and I am loving every spare minute I can muster trying to make up for lost time.
          </p>
          <p>
          Not completely lost though. I became an expert in all things agile and wore many hats along the way. Coach, teacher, psychologist, mold-breaker. Which is just the way I like it. But it is time to go back to doing what I love or morph into to some sort of kick ass hybrid agile coaching coder monkey project management extraordinaire. Gives true meaning to cross-funtional team member.
          </p>
          <p>
          But I digress. This is not the beginning or the end of my journey. It’s 4am and I got code to write before I get back to work. 
          </p>
        </div>
      </div>
        <Footer/> 
      </div>
    );
  }
export default BlogPageJourney;
