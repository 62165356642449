import React from "react";

// @material-ui/core component
import { makeStyles } from "@material-ui/core/styles";

// @react-icons
import {IoLogoJavascript} from "react-icons/io";
import {SiCsharp} from "react-icons/si";
import {FaJava} from "react-icons/fa";
import {FaJenkins} from 'react-icons/fa';
import {FaGitAlt} from 'react-icons/fa';
import {SiDotNet} from 'react-icons/si';
import {SiMicrosoftsqlserver} from 'react-icons/si';

// core components
import GridContainer from "../../../components/grid/grid-container.component";
import GridItem from "../../../components/grid/grid-item.component";
import Card from "../../../components/card/card.component";
import CardBody from "../../../components/card/card-body.component";
import CardFooter from "../../../components/card/card-footer.component";

import styles from './section.styles';

const useStyles = makeStyles(styles);

function SectionOlder() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Did I code that? - 2014</h2>
      <div>
        <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                MBF Identity Access Refactor
                <br />
                <small className={classes.smallTitle}>2014</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                Mitigated 10,000 web application security vulnerabilities
                 within one year for Identity and Access Management team 
                 by training them on SQL injection, cross-site scripting, 
                 and continuous integration and delivery for Boeing system 
                 accessed for ordering parts and obtaining schematics
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                 <FaJava size="28px" />
                 <FaJenkins size="28px"  />
                 <IoLogoJavascript size="28px"/>
                 <FaGitAlt size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                Nordstrom Secure Internal Link
                <br />
                <small className={classes.smallTitle}>2010</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                Designed and developed a communication tool to allow 
                approximately 6000 Nordstrom users to communicate 
                encrypted PII/PCI information to internal and external 
                recipients, in order to meet federal and state mandates.
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                 <SiCsharp size="28px" />  
                 <SiDotNet size="28px" />
                 <IoLogoJavascript size="28px"/>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                Nordstrom Vertex Tax Calculation
                <br />
                <small className={classes.smallTitle}>2008</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                Tax distribution tool which loads approximately 500,000-1,300,000 
                transactions daily. Required integration between Taleo, Vertex and custom apps.
                The data is distributed to the four taxing 
                authorities and is required for preparing accurate sales and use
                tax returns
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                 <FaJava size="28px"/>
                 <SiCsharp size="28px" /> 
                 <IoLogoJavascript size="28px"/>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                Nordstrom Vertex Test Platform
                <br />
                <small className={classes.smallTitle}>2008</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                Developed a sales tax test harness to provide the QAG, 
                production support team and the business owners to test
                 sales tax scenarios by submitting single or bulk 
                 transactions by uploading and parsing excel spreadsheets, 
                 text files or xml. 
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                 <SiCsharp size="28px" />  
                 <SiDotNet size="28px" />
                 <IoLogoJavascript size="28px"/>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                Nordstrom Bonus Accrual Reporting Refactor
                <br />
                <small className={classes.smallTitle}>2009</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Refactored application to remove code duplication in 
                  order to make it easier to maintain as well as increase performance.
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
              <SiMicrosoftsqlserver size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                Nordstrom Daily Notifications
                <br />
                <small className={classes.smallTitle}>2008</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Application to send processing results sent to Vertex as
                  well as errors, invoice info and summary 
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <SiCsharp size="28px" />  
                <SiDotNet size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                Motorola Deals
                <br />
                <small className={classes.smallTitle}>2007</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Developed some custom controls for client site. 
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <SiCsharp size="28px" />  
                <SiDotNet size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                Tracy Jewelers
                <br />
                <small className={classes.smallTitle}>2007</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Client Website 
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
              <SiCsharp size="28px" />  
              <SiDotNet size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                JFlo Personal Training
                <br />
                <small className={classes.smallTitle}>2006</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Client eCommerce site to include Admin portal.
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
              <SiCsharp size="28px" />  
              <SiDotNet size="28px" />
              <SiMicrosoftsqlserver size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                Outreach Services
                <br />
                <small className={classes.smallTitle}>2006</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Client Website 
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
              <SiCsharp size="28px" />  
              <SiDotNet size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                Super 8 Bobby Labonte Fan Site
                <br />
                <small className={classes.smallTitle}>2006</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Client Fan Website 
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
              <SiCsharp size="28px" />  
              <SiDotNet size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                Hoopfest Tournament Management System
                <br />
                <small className={classes.smallTitle}>2006</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Developed some custom controls for client site.
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
              <SiCsharp size="28px" />  
              <SiDotNet size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                EWU Student Admissions
                <br />
                <small className={classes.smallTitle}>2005</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                Developed and provided maintenance of the university 
                admissions web site serving 5000+ potential and existing
                 students annually. 
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
              <SiCsharp size="28px" />  
              <SiDotNet size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                DSP Security Ambassador 
                <br />
                <small className={classes.smallTitle}>2004</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                Designed and developed public facing website to provide
                Downtown Spokane business owners and residents online 
                report submission capabilities.
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
              <SiCsharp size="28px" />  
              <SiDotNet size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <h4 className={classes.cardTitle}>
                DSP Mobile Reporting System
                <br />
                <small className={classes.smallTitle}>2003</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                My very first professional project using C#, SQL and Toshiba Mobile Hand Held devices.
                 Developed the Security Ambassador Reporting system to 
                include web interface, asynchronous pocket pc application 
                and database allowing officers to create and submit reports.
                </p>
                <small className={classes.smallTitle}>Tech Stack</small>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
              <SiCsharp size="28px" />  
              <SiDotNet size="28px" />
              <SiMicrosoftsqlserver size="28px" />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

export default SectionOlder;